import React from "react";
import Layout from "../components/layout";
import { Container } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import SectionHeader from "../components/sectionHeader";

import "../styles/privacyPolicy.scss";

const PrivacyPolicy = () => {
  const policyPageData = useStaticQuery(graphql`
    query {
      allStrapiPrivacyPolicyPage {
        nodes {
          Content
          HeaderText
          HeaderTitle
          id
        }
      }
    }
  `);

  const data = policyPageData.allStrapiPrivacyPolicyPage.nodes[0];

  return (
    <Layout>
      <Container className="privacy">
        <div>
          <SectionHeader
            title={data.HeaderTitle}
            highlight="."
            text={data.HeaderText}
          />

          <ReactMarkdown source={data.Content} />
        </div>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
